

import './App.css';
import UsersList from './MyComponents/UsersList';
import Card from './MyComponents/Dashboard_Controls/Card';

//My functions from utils.js
import { remove } from './Javascript_Functions_to_export/Utils';
import { createTodos } from './Javascript_Functions_to_export/Utils';


// useContext hook : Call useContext at the top level of your component to read and subscribe to context.
import { useState, useRef, useMemo, React } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, Link } from 'react-router-dom';


//My components

import { formatDate } from './Javascript_Functions_to_export/Utils';
import SideBar from './MyComponents/SideBare/SideBar';
import RightSide from './MyComponents/RightSide/RightSide';
import Gauge from './MyComponents/Gauges/Circular/Gauge';


import ToastContainer from './MyComponents/Toast/ToastContainer';          // components/ToastContainer';
import { ToastProvider } from './MyComponents/Toast/ToastContext';   // context/ToastContext';



//const App = () => {  // or function App () { ... }
function App () {
//function App ({ Component, pageProps }) {


  const [count, setCount] = useState(0);
  // const incrementCount = () => { setCount (count + 1); }
  // arrow function refrence : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions
  
  const copyArr = () => {  // the function name is copyArr
    const oldArr = [ {id: 1, name: "Apple"}, {id: 3, name: "Orange"}, {id: 3, name: "Banana"} ];
    var eltToRemove = { id: 3, name: "Banana"};
    var removed = remove(oldArr, eltToRemove);
    
    //console.log("removed = ", removed);
    console.log("oldArr = ", oldArr);

    const refArr= oldArr;
    const clonedArr = [...oldArr];  // ES6 way  |  old way : using slice() without any parameters
    const filteredObjects = clonedArr.filter(el => el.id >= 2);
  } 
  
  //const justVal = copyArr();  // once we write the (), the function will be called.

  const todosArr = [];

  //createTodos(); // every time it will render beacuse the () syntax, means call that function

  const countRef = useRef(0);     // the value will not be updated in the DOM, untill the component will trigger

  const incrementCount = () => { 
    countRef.current = countRef.current + 1; 
    console.log("countRef.current = ", countRef.current);
  }

  var currDate = Date.now;
  var size = 100;


  //process.env.REACT_APP_PORT = 800;
      // Access .env data
      //console.log("REACT_APP_PORT = ", process.env.REACT_APP_PORT);

/*
  fetch('http://localhost:xxxx/tesdatetime', {
      method: "POST",
      mode: "cors",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({name : ''})
    })
    .then((data) => {
        toast("success","Your job has been posted successfuly,\nThanks for using hiremeworld")
    })
    .catch(e => {
        console.log("error", e);
    })*/


  return (
    <>
    <div>
            <ToastProvider>
              <BrowserRouter>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <SideBar />
                  <RightSide />  
                </div>
              </BrowserRouter>

              <ToastContainer />
            </ToastProvider>
    </div>

      

    </>
  );
}

export default App;
