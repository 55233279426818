
import './SideBar.css';
import { Link, NavLink } from 'react-router-dom';

import { useMatch, useResolvedPath } from 'react-router-dom';

import Logo from '../../aImages/logo.jpg';
import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { getUserFromCookie } from '../../Javascript_Functions_to_export/Utils';


const SideBar = ({ isHidden }) => {


    return(
        <>
            {/* <div className={`side-bar-container ${ isHidden === true ? "hidden" : "visible" }`}> */}
            
        </>
    );
}






export default SideBar;