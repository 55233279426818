
import './LogIn.css';

import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Footer from '../Footer/Footer';

import { useNavigate, useLocation } from 'react-router-dom';

import { useToast } from '../../CustomeHooks/useToast';

import validator from 'validator';

const LogIn = ({ setLogoFn, sendUsernameFn, userWant }) => {
//const LogIn = ({ setLogoFn, sendUsernameFn }) => {

    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();

    const [username, setUsername] = useState("employer");
    const [password, setPassword] = useState("sP@ssw0rdc");
    const [passIsHidden, setPassIsHidden] = useState(true);

    const [emailResetPassword, setEmailResetPassword] = useState('');   // email to send to.
    const [isSending, setIsSending] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    //console.log("document.referrer = ", document.referrer);
    
    //const [backIn, setBackIn] = useState(false);

    useEffect(() => {
        console.log("in useEffect", userWant, "loc", location);
        // if(userWantsToResetPass && userWant) {   
        //     setUserWantsToResetPass(false);
        // }
    });

    const logout = () => {

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        //fetch('http://localhost:808x/logout', {
        fetch(process.env.REACT_APP_URL + '/logout', {
            method: 'GET',
            mode: 'no-cors',
            redirect: 'follow',
            credentials: 'include',
            headers: headers
        })
        .then((res) => {
            

            localStorage.removeItem('Logo');
            // We commented this line, because we want to leave the user in the same page. 
            // and not redirect him to another page
            //navigate('/login');
            
            // So once the user logout. refresh the page
            
            if(!location || !location.pathname)
                //window.location.href = "http://localhost:300x";
                window.location.href = process.env.REACT_APP_ORIGIN;
            else {
                if(location.pathname === '/admin')
                    //window.location.href = "http://localhost:300x";
                    window.location.href = process.env.REACT_APP_ORIGIN;
                else
                    if(location.pathname == "/search" && localStorage.getItem('search'))
                        //window.location.href = "http://localhost:300x" + location.pathname + localStorage.getItem('search');
                        window.location.href = process.env.REACT_APP_ORIGIN + location.pathname + localStorage.getItem('search');
                    else {
                        if(location.pathname === '/signup')
                            //window.location.href = "http://localhost:300x";    
                            window.location.href = process.env.REACT_APP_ORIGIN;    
                        else
                            //window.location.href = "http://localhost:300x" + location.pathname;
                            window.location.href = process.env.REACT_APP_ORIGIN + location.pathname;
                    }
            }
        })
    }

    axios.defaults.withCredentials = true;
    const handleSubmit = (e) => {
        e.preventDefault();

        // There is a condition on "Back End" level as well.
        if(document.cookie) {
            toast("success", "You are already logged in");
            return;
        }

        if(!username || (username && username.trim().length === 0)){
            toast("warning", "Username is required");
            return;
        }

        if(!password || (password && password.trim().length === 0)){
            toast("warning", "Password is required");
            return;
        }

        //console.log("account= ", { username, password });
        var user = {
            Username: username,
            Password: password
        }

        setIsLoggingIn(true);

        //axios.post('http://localhost:808x/login', user)
        axios.post(process.env.REACT_APP_URL + '/login', user)
        .then(res => {
            //console.log("res.data = ", res.data);
            if(res.data.status === "Success") {
                //console.log("res = ", res);
                if(res.data.isAdmin) {
                    navigate("/admin");
                }
                else {
                    
                    //localStorage.setItem('search', location.search);
                    //console.log('location = ', location);
                    //return;
                    //setLogoFn(res.data.user.Logo);
                    localStorage.setItem('Logo', res.data.user.Logo);

                    if(location.state) {
                        //console.log("location.state = ", location.state);
                        
                        if(location.state.LastVisitedPage === "/search" && localStorage.getItem('search')) {
                            //console.log("state.LastVisPage + storage(search) = ", 
                                            //location.state.LastVisitedPage + localStorage.getItem('search'));
                            navigate(location.state.LastVisitedPage + localStorage.getItem('search'));
                        }
                        else {
                            //console.log(" LastVisitedPage = ", location.state.LastVisitedPage);
                            if(location.state.LastVisitedPage === '/signup' ||
                               location.state.LastVisitedPage === '/login' ) {
                                navigate("/");
                            }
                            else {
                                //console.log("location.state 44 = ", location.state);
                                navigate(location.state.LastVisitedPage);
                            }
                        }
                    }
                    else
                        navigate("/");
                }
                //window.location.href = "http://localhost:X000/";
            }
        })
        .catch(err => {
            console.log("err = ", err);

            if(err.response.status === 401 || err.response.status === 500) {
                toast("error", err.response.data.message);
            }
        })
        .finally(() => {
            setIsLoggingIn(false);
        });
    }

    const backToLogin = () => {
        setUserWantsToResetPass(false);
    }
     
    const [userWantsToResetPass, setUserWantsToResetPass] = useState(false);

    const forgotPassword = (e) => {
        e.preventDefault();

        if(!username || username.trim() === '') {
            toast('warning', 'Please enter your username.');
            return;
        }

        setUserWantsToResetPass(true);

        sendUsernameFn(username.trim());

        //navigate('/forgotpassword');
    }

    const resetPassword = (e) => {
        e.preventDefault();

        if(!emailResetPassword) {
            toast('warning', 'Email is required');
            return;
        }

        if(!validator.isEmail(emailResetPassword)) {
            toast("warning", "Invalid Email !");
            return;
        }
  
        //console.log("in forgot pass", emailResetPassword);

        var data = {
            Username: username,   // if null => the type on the server side will be "Object" and not string
            Email: emailResetPassword,
        };
        setIsSending(true);

        console.log("data=", data);

        axios.defaults.withCredentials = true;
        //axios.post('http://localhost:808x/forgot_password', data)
        axios.post(process.env.REACT_APP_URL + '/forgot_password', data)
        .then((res) => {
            //console.log("inside then");
            toast('success', res.data.message);
            setUserWantsToResetPass(true);
        })
        .catch((err) => {
            //console.log("inside catch", err);
            if(err.response.status === 401) {
                toast('error', err.response.data.message);
            }
        })
        .finally((e) => {
            setIsSending(false);
        })
    }


    const toggleVisibility = () => {
        var x = document.getElementById("password");
        if (x.type === "password") {
          x.type = "text";
          setPassIsHidden(false);
        } else {
          x.type = "password";
          setPassIsHidden(true);
        }
    }


    return(<>

        <div className="login-container every-page-container">
    
        {
            !document.cookie ?
            <>
                
                    <div className="container-fluid">
                        <div className='row'>
                            
                            <div className='left-side'>
                                <div className='twm-log-reg-media'>
                                    <div className='twm-l-media'>
                                        <img src="/login-bg.png" alt='login-bg' />
                                    </div>
                                </div>
                            </div>


                        
                            <div className='page-loader'>
                                <div className='twm-log-reg-form-wrap'>
                                    <div className='twm-log-reg-logo-head'></div>
                                    <div className='loginid'></div>
                                    <div className='twm-log-reg-inner'>
                                        <div className='twm-log-reg-head'>
                                            <div className='twm-log-reg-logo'>
                                                <span className='log-reg-form-title'>
                                                    {
                                                        !userWantsToResetPass ? 'Log In' : 
                                                        username && userWantsToResetPass ? 'Reset Password' : ''
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className='twm-tabs-style-2'>
                                            <div className='tab-content'>
                                                <div className='tab-pane'>
                                                    <div className='row'>


                                                        <form onSubmit={ handleSubmit }>

                                                            {/* Login page*/}

                                                            { 
                                                                !userWantsToResetPass && 
                                                                <div className='login-fields'>
                                                                    <div className="element">
                                                                        <input type="text" 
                                                                            name="username" 
                                                                            className="input-field"
                                                                            value= { username }
                                                                            maxLength='50'
                                                                            placeholder='Username (required)' 
                                                                            onChange={ (e) => setUsername(e.target.value) }/>
                                                                    </div>

                                                                    <div className="element" style={{ position: 'relative' }}>

                                                                        {
                                                                            passIsHidden ? 
                                                                            password && 
                                                                            <i className="fa fa-eye show-hide-eye" 
                                                                                    aria-hidden="true"
                                                                                    onClick={ toggleVisibility }></i>
                                                                            :
                                                                            password && 
                                                                            <i className="fa fa-eye-slash show-hide-eye" 
                                                                                aria-hidden="true"
                                                                                onClick={ toggleVisibility }></i>
                                                                        }


                                                                        <input type="password" 
                                                                            name="password"
                                                                            id='password'
                                                                            maxLength='30'
                                                                            className="input-field"
                                                                            value={ password }
                                                                            placeholder='Password (required)' 
                                                                            onChange={ (e) => setPassword(e.target.value) }/>
                                                                    </div>

                                                                    <div className="element">
                                                                        <button className='forgot-password' 
                                                                                name='forgotpassword'
                                                                                type='button'
                                                                                onClick={ forgotPassword }>Forgot Password ?</button>
                                                                    </div>

                                                                    <div className='signup-login element'>
                                                                        <div className="signup-div">
                                                                            { 
                                                                                !isLoggingIn && 
                                                                                <Link to='/signup' className='signup-submit'>
                                                                                {/* Create Account */}
                                                                                Sign Up
                                                                                </Link>
                                                                            }
                                                                        </div>
                                                                        <div className="login-div">
                                                                            
                                                                        <input name='login'
                                                                                type='submit' 
                                                                                className={`login-submit${isLoggingIn ? ' disabled' : ''}`}
                                                                                value={`${isLoggingIn ? 'Logging In' : 'Log In'}`} 
                                                                                disabled={ isLoggingIn } />

                                                                            
                                                                            {/* <input className="login-submit" 
                                                                                type="submit" 
                                                                                name="login" 
                                                                                value="Log In" /> */}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            }

                                                            {/* Reset Password page*/}
                                                                                                            
                                                            {
                                                                username && userWantsToResetPass &&
                                                                <div className='three-rows'>
                                                                    
                                                                    <div className='email-div'>
                                                                        <input name="email" 
                                                                                type="text"
                                                                                placeholder='Email (required)'
                                                                                maxLength='50'
                                                                                className='email-itself'
                                                                                value={ emailResetPassword }
                                                                                onChange={ (e) => setEmailResetPassword(e.target.value) }/>
                                                                    </div>

                                                                    <div className='back-to-login-div'>
                                                                        <button className='back-to-login-itself' 
                                                                                name='backToLogin' 
                                                                                onClick={ backToLogin }>
                                                                                Back To Login
                                                                        </button>
                                                                    </div>

                                                                    <div className='reset-password-div'>
                                                                        
                                                                         <button name='resetpassword'
                                                                                className={`reset-password-itself${ isSending ? ' disabled' : ''}`}
                                                                                disabled={ isSending }
                                                                                onClick={ resetPassword }>
                                                                         { 
                                                                            isSending ? 
                                                                            'Sending ...' : 
                                                                            'Send me the new Password'
                                                                         }

                                                                         </button>
                                                                        
                                                                        
                                                                        {/* {
                                                                            !isSending && 
                                                                            <button className='reset-password-itself'
                                                                                    name='resetpassword' 
                                                                                    onClick={ resetPassword }>
                                                                                    Send me the new Password
                                                                            </button>
                                                                        }*/}
                                                                    </div>

                                                                </div>
                                                            }

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div className='footer'>
                        <hr width='80%' style={{ marginRight: 'auto', marginLeft: 'auto', marginTop: '58px', }} />
                        <div style={{ color: 'rgb(165 161 161)',
                                    marginLeft: '10%',
                                    marginTop: '18px',
                                    marginBottom: '18px' }}>
                            Copyright © { new Date().getFullYear() } All rights reserved.
                        </div>
                    </div>           
             </>   
            :
            <>
            <div style={{
                    // height: '440px',
                    height: 'calc(100vh - 60px)',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                                    }}>
                <div className="loggedin-block">
                    You are logged in. <u><button onClick={ logout } className='back'>Log out</button></u>
                </div>
            </div>
            <div className='footer' style={{ position: 'absolute',
                                             bottom: '0'}}>
                <hr width='80%' style={{ marginRight: 'auto', marginLeft: 'auto', marginTop: '58px', }} />
                <div style={{ color: 'rgb(165 161 161)',
                            marginLeft: '10%',
                            marginTop: '18px',
                            marginBottom: '18px', verticalAlign: 'bottom', }}>
                    Copyright © { new Date().getFullYear() } All rights reserved.
                </div>
            </div></>
        }

        {/* <div className='footer'>
            <hr width='80%' style={{ marginRight: 'auto', marginLeft: 'auto', marginTop: '58px', }} />
            <div style={{ color: 'rgb(165 161 161)',
                        marginLeft: '10%',
                        marginTop: '18px',
                        marginBottom: '18px' }}>
                Copyright © { new Date().getFullYear() } All rights reserved.
            </div>
        </div> */}


        </div>
    </>
    )
}


export default LogIn;