

import './Footer.css';

const Footer = () => {

    return(<>

        <hr width='80%' style={{ marginRight: 'auto', marginLeft: 'auto', marginTop: '58px', }} />
        <div style={{ color: 'rgb(165 161 161)',
                      marginLeft: '10%',
                      marginTop: '18px',
                      marginBottom: '18px' }}>
            Copyright © { new Date().getFullYear() } All rights reserved.
        </div>

    </>);
}

export default Footer;