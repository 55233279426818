


import './Toast.css';


import Toast from './Toast';    // components/Toast';
import { useToastStateContext } from './ToastContext';  // context/ToastContext';

export default function ToastContainer() {
  const { toasts } = useToastStateContext();
  return (
    <>
      {  
        toasts &&
        Array.isArray(toasts) && 
        toasts.length > 0 &&
        <div className='toast-container'>
          { 
            toasts.map((toast) => (
              <Toast
              id={ toast.id }
                key={ toast.id }
                type={ toast.type }
                message={ toast.message }
                />
              ))
          }
        </div>
      }
    </>
  );
}