

import NotFound404_1 from '../../aImages/404_1.jpg';
import NotFound404_2 from '../../aImages/404_2.jpg';
import NotFound404_3 from '../../aImages/404_3.jpg';

import './NotFound.css';
import { Link } from 'react-router-dom';

const NotFound = () => {

    return(
        <div className='notfound-container every-page-container'>
            <img src={ NotFound404_3 } alt='Not Found 404' 
                        style={{ width: '100%', height: '300px', position: 'absolute' }}/>

            <div className='blue-div'>
                                {/* 404 error */}
            </div>

            <div className='home-404error'>
                Home &gt; 404error
            </div>


            <div className='page-content'>
                <div className='number-404'>
                    <div className='left-4 number'>
                        4
                    </div>
                    <div className='zero number'>
                        0
                    </div>
                    <div className='right-4 number'>
                        4
                    </div>
                </div>
                <div className='bottom-text'>
                    SORRY, THIS PAGE ISN'T AVAILABLE
                </div>
                <div className='small-horiz-line'>
                    <hr />
                </div>
                <div className='home-page-link'>
                    <Link to='/'>Home Page</Link>
                </div>
            </div>

            <hr width='80%' style={{ marginRight: 'auto', marginLeft: 'auto', marginTop: '58px', }} />
            <div style={{ color: 'rgb(165 161 161)',
                        marginLeft: '10%',
                        marginTop: '18px',
                        marginBottom: '18px' }}>
                Copyright © { new Date().getFullYear() } All rights reserved.
            </div>

        </div>     
    );
}

export default NotFound;





{/* <>
            <h1>
                How would you get here ?
            </h1>
            <p>
                It looks like this page does not existing - SORRY about that ! 
            </p>
        </> */}