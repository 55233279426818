

import './JobItem.css';
import { Link } from 'react-router-dom';
import { getEmploymentTypes, getWorkPlaces } from '../../Javascript_Functions_to_export/Utils';
import { useState, useEffect } from 'react';

import { showLocalTime } from '../../Javascript_Functions_to_export/Utils';


const JobItem = ({ _jobItem}) => {

    const [employmentTypes, setEmploymentTypes] = useState();
    const [workPlaces, setWorkPlaces] = useState();

    //console.log("item", _jobItem);

    useEffect(() => {
        setEmploymentTypes(getEmploymentTypes());
        setWorkPlaces(getWorkPlaces());
    }, []);

    return(
        <div
            //   className='single-job-row' to={`/job/details/${_jobItem.ID}`} >
              className='single-job-row' >
                <div className='col1'>
                    {/* <img src={`./comp${_key + 1}.JPG`}   */}
                    {
                        _jobItem.Logo !== null && !_jobItem.IsConfidential ? 
                            <img src={ "/" + _jobItem.Logo } 
                                alt={ _jobItem.Logo } 
                                className='company-logo' /> 
                            :
                        _jobItem.Logo === null && !_jobItem.IsConfidential ?
                            <i className="fa fa-picture-o" 
                            aria-hidden="true"
                            style={{ fontSize: '125px', opacity: '0.2'}}>
                            </i> 
                        :
                        <i className="fa fa-shield" 
                            aria-hidden="true" 
                            style={{ fontSize: '165px'}}></i>
                    }
                </div>
                
                <div className='col2'>
                    <div className='title'>
                        <Link to={`/job/details/${_jobItem.JobID}`}>
                            { 
                                _jobItem.JobTitle.length <= 50 ? 
                                _jobItem.JobTitle : 
                                _jobItem.JobTitle.slice(0, 40) + " ..."
                            }
                        </Link>
                    </div>
                    <div className='location'>
                        { 
                            // typeof(_jobItem.Location) + '  ' + _jobItem.Location 
                            _jobItem.Location === 'null' || 
                            _jobItem.Location === null || 
                            _jobItem.Location.trim() === '' ?
                            'Location: not specified' :
                            _jobItem.Location
                        }
                        {/* <span style={{ display: 'inline-block', 
                                       borderRadius: '50%',
                                       background: '#10bd10',
                                       width: '7px',
                                       height: '7px', 
                                       margin: '0 5px 0 8px' }}></span> */}
                        {/* <span className='workplace'>
                        { 
                            workPlaces && _jobItem && 
                            workPlaces.find(g => g.Id === _jobItem.Workplace) ?
                            workPlaces.find(g => g.Id === _jobItem.Workplace).Name : 
                            <span className='unknown-val'></span> 
                        }
                        </span> */}
                    </div>  
                    <div className='company-name'>Company name: &nbsp;&nbsp;
                        { 
                            _jobItem && _jobItem.IsConfidential === 1 ? 
                            'Confidential' : 
                            _jobItem && _jobItem.CompanyName 
                        } 
                    </div>  
                </div>

                <div className='col3'>
                    <div className='employment-type'>
                    { 
                        workPlaces && _jobItem && 
                        _jobItem.Workplace === 10 && workPlaces.find(g => g.Id === _jobItem.Workplace) ? 
                        'Place: ' + workPlaces.find(g => g.Id === _jobItem.Workplace).Name 
                        :
                        workPlaces && _jobItem &&
                        workPlaces.find(g => g.Id === _jobItem.Workplace) ?
                        workPlaces.find(g => g.Id === _jobItem.Workplace).Name
                        :
                        <span className='unknown-val'></span> 
                    }
                    </div>
                    <div className='salary'>
                        Salary : { _jobItem && !_jobItem.Salary ? 
                                   'Not Specified' : 
                                   _jobItem && _jobItem.Salary }
                    </div> 
                   
                    <div className='postingdate'>{ showLocalTime(_jobItem).PostingDate }</div>
                </div>
        </div>
    )
}

export default JobItem;