

import './CompanyProfile.css';

import useGetByID from "../../CustomeHooks/useGetByID";
import FileUploader from '../Uploader/FileUploader';

import { Link, useParams, Navigate, useNavigate, useLocation } from 'react-router-dom';

import { useToast } from '../../CustomeHooks/useToast';
import { useEffect, useRef, useState } from 'react';

import axios from "axios";

import validator from 'validator';

import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";

import { getUserFromCookie } from '../../Javascript_Functions_to_export/Utils';

const CompanyProfile = ({ setLogoFn }) => {

    const navigate = useNavigate();
    const toast = useToast();
    const location = useLocation();

    const { id } = useParams();
    const { lsData: currItem, isLoading, found, useEmailBody, useCompanyProfile } = 
    //useGetByID('http://localhost:808x/company/' + id);
    useGetByID(`${process.env.REACT_APP_URL}/company/${id}`);

    const [currCompany, setCurrCompany] = useState(null);


    useEffect(() => {
        setCurrCompany(currItem);

        setEmailBody(useEmailBody ? useEmailBody : '');
        setProfile(useCompanyProfile ? useCompanyProfile : '');

        console.log("company = ", currItem);
        if(currItem) {
            localStorage.setItem('Logo', currItem.Logo);   // new code
        }
        //setLogoFn(currItem.Logo);   // old code

    }, [currItem])

    useEffect(() => {
        
        //console.log("in useEffect hook");

        // user navigate to this page manually (using url)
        if(!location.state && !document.cookie) {
            toast('warning', 'Please login first !');
            navigate('/login');
        }
        else {
            // user already was in "CompanyProfile" page, and clicks "Logout"
            if(location && location.state.LastVisitedPage.indexOf("/company/details/") >= 0 && 
               !document.cookie) {
                navigate('/login');
            }
        }
    }, []);

    const [companyNameError, setCompanyNameError] = useState('');

    const _setCompanyName = (e) => {

        if(e && e.target) {
            if(e.target.value.trim().length === 0)
                setCompanyNameError('Company name is required');
            else
                setCompanyNameError('');

            setCurrCompany({...currCompany, CompanyName: e.target.value });
            //setCompanyName(e.target.value);
        }
    }
    
    const changeIsConfidential = (e) => {
        //console.log('e.target = ', e.target.checked);   // e.target.value : did not work
        //setConfidential(e.target.checked);

        if(e && e.target)
            setCurrCompany({...currCompany, IsConfidential: e.target.checked });
    };

    const [companyEmailError, setCompanyEmailError] = useState('');
    
    const _setCompanyEmail = (e) => {
        if(e && e.target) {
            if(e.target.value.trim().length === 0)
                setCompanyEmailError('Company email is required');
            else
                setCompanyEmailError('');

            setCurrCompany({...currCompany, Email: e.target.value });
        }
    }

    const _setLocation = (e) => {
        if(e && e.target)
            setCurrCompany({...currCompany, Location: e.target.value });
    }


    const _setEmailSubject = (e) => {
        if(e && e.target)
            //setEmailSubject(e.target.value);
            setCurrCompany({...currCompany, EmailSubject: e.target.value });
    }


    const [emailBody, setEmailBody] = useState('');
    const [profile, setProfile] = useState('');

    const [isSaving, setIsSaving] = useState(false);
    
    const _setProfile = (e) => {
        if(e && e.target) {
            setProfile(e.target.value);
        }
    }

    // link reference : https://quilljs.com/docs/modules/toolbar
    const _modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'color': [] }, { 'background': [] }],
        ]
    };

    const [file, setFile] = useState();

    const x = (_files) => {
        setFile(_files[0]);
        console.log("user Made an action");
        userMakeAction.current = true;
    }

    const userMakeAction = useRef(false);

    const removeLogoFile = () => {
        userMakeAction.current = true;

        setFile(null);
        //setBackUpLogo(currCompany.Logo);
        setCurrCompany({...currCompany, Logo: null});
    }

    const [isAddingCV, setIsAddingCV] = useState(false);

    const getIsAddingCV = (_isAdding) => {
        setIsAddingCV(_isAdding);
    }

    axios.defaults.withCredentials = true;

    const handleSubmit = (e) => {

        e.preventDefault();

        if(!currCompany) {
            toast("error", "Server error ! Company is null");
            return;
        }

        if(!currCompany.CompanyName || currCompany.CompanyName.trim() === '') {
            toast("warning", "Company Name is required !");  
            return;
        }

        if(!currCompany.Email || currCompany.Email.trim() === '') {
            toast("warning", "Company Email is required !");  
            return;
        }


        // if((currCompany.CompanyName && currCompany.CompanyName.trim().length === 0) || 
        //     currCompany.Email && currCompany.Email.trim().length === 0) {
        //     toast("warning", "Please fill all required fields !");    
        //     //toast.warning("Please fill all required fields !");    
        //     return;
        // }
        
        if(!validator.isEmail(currCompany.Email)) {
            toast("warning", "Invalid Email !");
            return;
        }
      
        setIsSaving(true);

        var currCompanyFormData = new FormData();
        // fields to update cookie, to check if company can post jobs or not:
        currCompanyFormData.append("ID", currCompany.ID);
        currCompanyFormData.append("Email", currCompany.Email);
        currCompanyFormData.append("Username", currCompany.Username);
        currCompanyFormData.append("AccountType", currCompany.AccountType);

        if(document.cookie) {
            currCompanyFormData.append("token", getUserFromCookie(document.cookie).token);
        }

        currCompanyFormData.append("CompanyName", currCompany.CompanyName);
        currCompanyFormData.append("IsConfidential", currCompany.IsConfidential === true || currCompany.IsConfidential === 1 ? 1: 0);
        currCompanyFormData.append("Location", currCompany.Location);
        //currCompanyFormData.append("EmailSubject", currCompany.EmailSubject);

        currCompanyFormData.append("EmailBody", emailBody);
        currCompanyFormData.append("Profile", profile); 

        //console.log("EmailBody = ", emailBody, " || Profile", profile);

        //return;

        if(!userMakeAction.current) {
            currCompanyFormData.append("updateLogoColumn", false);
            currCompanyFormData.append("currentLogo", currCompany.Logo);
            console.log("in 1");
        }
        else {
            if(file) {  // user takes action : add a new logo
                currCompanyFormData.append("file", file);
                currCompanyFormData.append("updateLogoColumn", true);
                currCompanyFormData.append("fileName", file.name);
                console.log("in 2");
            }
            else {  // user deletes the Logo and save
                    console.log("in 3");
                    currCompanyFormData.append("updateLogoColumn", true);
                    currCompanyFormData.append("fileName", 'empty');
                    currCompanyFormData.append("currentLogo", null);
            }
        }

        //axios.put('http://localhost:808x/update_company', currCompanyFormData)
        axios.put(`${process.env.REACT_APP_URL}/update_company`, currCompanyFormData)
        .then(res => {
            if(res.data.status === 'success') {
                toast('success', 'Saved.');
                
                
                //navigate('/company/details/' + id);
                //window.location.href = "http://localhost:XXXX/company/details/" + id;   // its problem is: the toast does not appear
                
                //console.log("after sending, uniqueFileName=", res.data.uniqueFileName);
                
                localStorage.setItem('Logo', res.data.uniqueFileName);
                setLogoFn(res.data.uniqueFileName);

            }
            else
                toast("error", res.data.error);
        })
        .catch(err => {
            if(!err.response)
                toast("error", "Unexpected Error has occured !");
            else { 
                if(err.response.status === 401) {
                    toast("error", err.response.data.message);
                }
            }
        })
        .finally(() => {
            setIsSaving(false);
        })
    }


    return(
        <div className="company-profile-container every-page-container">
                {
                    currCompany && 
                    <form onSubmit={ (e) => handleSubmit(e) }>


                    


                    <div className='page-container'>
                        <div className='title-section'>
                            <div className='title'>
                                <h2>Company Profile</h2>
                            </div>
                            <div className='path'>
                                <div className='home'>Menu</div>
                                <div className='circle'></div>
                                <div className='change-password'>Profile</div>
                            </div>
                        </div>

                        <div className='fields-section'>
                            <div className='row-wrap'>

                                <div className='logo-file-section'>
                                        <div className='diff-margin'>
                                            Company Logo
                                        </div> 
                                        <div style={{ position: 'relative' }}>
                                            <div style={{ position: 'absolute', left: '90px', top: '-16px' }}>
                                                { 
                                                    (file || currCompany.Logo) && 
                                                    // && <button onClick={ removeLogoFile }>Remove Logo</button>
                                                    <i className="fa fa-times" 
                                                    aria-hidden="true" 
                                                    style={{ color: 'red', zIndex: '20', cursor: 'pointer', fontSize: '35px' }}
                                                    onClick={ removeLogoFile } ></i>
                                                }
                                            </div>

                                            <div style={{ zIndex: '2' }}>
                                                { 
                                                    currCompany.Logo && !file &&
                                                    <img src={`/${currCompany.Logo}`} 
                                                            alt='company logo'
                                                            style={{ width: '120px', 
                                                                    height: '110px',
                                                                    width: '120px',
                                                                    height: '110px',
                                                                    borderRadius: '10px', }} /> 
                                                }
                                                {
                                                    file &&
                                                    <img id="tempLogo" 
                                                        src={file.URL} 
                                                        style={{ width: '120px', 
                                                            height: '110px',
                                                            width: '120px',
                                                            height: '110px',
                                                            borderRadius: '10px', }}/>
                                                }
                                                {
                                                    !file && !currCompany.Logo &&
                                                    <>
                                                        <FileUploader XXX={ x } 
                                                            isAddingFn={ getIsAddingCV } 
                                                            id='file-uploader'
                                                            btnText='Add Logo'
                                                            _accept="image/png, image/jpeg, image/jpg" />

                                                        <i className="fa fa-picture-o" 
                                                        aria-hidden="true"
                                                        style={{
                                                                width: '120px',
                                                                height: '110px',
                                                                borderRadius: '10px',
                                                                fontSize: '110px',
                                                                opacity: '0.14' }}>
                                                        </i>
                                                        {/* <div style={{ position: 'absolute', 
                                                                    top: '55px',
                                                                    left: '60px',
                                                                    color: '#c1c1c1',
                                                        }}>
                                                            No Image
                                                        </div> */}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                </div>

                                <div className='btn-save-profile field-comp' id='div-save-profile' >
                                    
                                <input name='saveChanges'
                                        type='submit' 
                                        className={`profile-btn-itself${ isSaving === true || isAddingCV === true ? ' disabled' : ''}`}
                                        value={ 
                                            isAddingCV === true ? 'Save' :
                                            isSaving === true ? 'Saving' : 
                                            'Save'
                                        } 
                                        // {`${ isSaving ? 'Saving' : 'Save'}`} 
                                        disabled={ isSaving === true || isAddingCV === true } />
                                    
                                    {/* { 
                                        <input name='saveChanges'
                                            type='submit' 
                                            className='profile-btn-itself'
                                            style={{ visibility: !isSaving ? 'visible' : 'hidden' }}
                                            value='Save' />
                                    } */}
                                </div>


                                <div className='company-name-section field-comp field-comp-pr'>
                                    <div className='comp-title'>
                                        Company Name <span style={{ color: 'red' }}>*</span>
                                    </div> 
                                    <div className='field-container'>
                                        <input type="text" 
                                                name='companyname'
                                                value={ currCompany.CompanyName || ''} 
                                                onChange={ _setCompanyName } 
                                                // placeholder= 'The official company name under which your business is registered' 
                                                placeholder= 'The official company name' 
                                                maxLength='50'
                                                className='input-itself' />       
                                    </div> 
                                </div>

                                <div className='company-confid-section field-comp field-comp-pl field-comp-pr'>
                                    <div className='comp-title'>
                                        Show company name as Confidential
                                    </div> 
                                    <div className='field-container' 
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            height: '55px' }}>
                                        <input type="checkbox"
                                                id="companyconfid_id"
                                                name='companyconfid'
                                                style={{ width: '20px', 
                                                        height: '20px', 
                                                        cursor: 'pointer'}}
                                                
                                                checked= { currCompany.IsConfidential }
                                                value={ currCompany.IsConfidential || ''} 
                                                onChange={ changeIsConfidential } />
                                        <label style={{ fontSize: '11px',
                                                       color: 'rgb(161 161 161)',
                                                       paddingLeft: '13px',
                                                        cursor: 'pointer' }}
                                                htmlFor='companyconfid_id'>
                                            If checked, 'Confidential' will appear instead of Company Name.
                                        </label> 
                                    </div> 
                                </div>
    
                                <div className='company-email-section field-comp field-comp-pl'>
                                    <div className='comp-title'>
                                        Company Email <span style={{ color: 'red' }}>*</span>
                                    </div> 
                                    <div className='field-container'>
                                        <input type="text"
                                                name='companyemail'
                                                className='input-itself'
                                                value={ currCompany.Email || ''} 
                                                maxLength='50'
                                                placeholder= 'The work company email'
                                                onChange={ _setCompanyEmail} />       
                                    </div> 
                                </div> 

                                <div className='company-location-section field-comp'>
                                    <div className='comp-title'>
                                        Company Location
                                    </div> 
                                    <div className='field-container'>
                                        <input type="text"
                                                name='companylocation'
                                                className='input-itself'
                                                // value={ currCompany.Location || ''}
                                                value={ currCompany.Location === 'null' ? '' : currCompany.Location }
                                                maxLength='60'
                                                placeholder= 'e.g Lebanon - Beirut'
                                                onChange={ _setLocation }  />   
                                    </div> 
                                </div>



                                {/* <div className='company-emailsubject-section field-comp field-comp-pl'>
                                    <div className='comp-title'>
                                        Email Subject
                                    </div> 
                                    <div className='field-container'>
                                        <input type="text"
                                                name='companyemailsubject'
                                                className='input-itself'
                                                value={ currCompany.EmailSubject || ''}
                                                maxLength='100'
                                                placeholder="The email subject that you will receive once someone applies for your job."
                                                onChange={ _setEmailSubject } />   
                                    </div> 
                                </div> */}



                                <div className='company-emailbody-section field-comp field-comp-pr'>
                                    <div className='comp-title'>
                                        Email Body
                                    </div> 
                                    <div className='field-container react-quill-div'>
                                        <ReactQuill 
                                            theme="snow" 
                                            value={ emailBody ? emailBody : '' } 
                                            modules={_modules}
                                            onChange={ setEmailBody } 
                                            name="emailbody"
                                            placeholder="Dear hiring manager(s),&#10;
                                            I recently came across your 'Job Title' job. &#10;
                                            
                                            My resume is attached for your review.&#10;
                                            If you would like more information regarding my qualifications for this position,
                                            please do not hesitate to reach out.&#10;&#10;
                                            
                                            Thank you so much for your time and consideration for this position,
                                            and I look forward to hearing from you soon.&#10;&#10;
                                            
                                            Sincerely," />
                                    </div> 
                                </div>

                                <div className='company-company-profile-section field-comp field-comp-pl'>
                                    <div className='comp-title'>
                                        Company Profile
                                    </div> 
                                    <div className='field-container react-quill-div'>
                                        <ReactQuill 
                                            theme="snow" 
                                            value={ profile ? profile : '' } 
                                            modules={_modules}
                                            onChange={ setProfile } // worked properly
                                            name="emailbody"
                                            placeholder="Introduction of the business which aims to inform 
                                            the audience about your products and services." />
                                    </div> 
                                </div>

                                <div className='btn-save-profile field-comp' id='div-save-profile_2' >
                                    
                                    
                                <input name='saveChanges_2'
                                        type='submit' 
                                        className={`profile-btn-itself${ isSaving === true || isAddingCV === true ? ' disabled' : ''}`}
                                        value={ 
                                            isAddingCV === true ? 'Save' :
                                            isSaving === true ? 'Saving' : 
                                            'Save'
                                        } 
                                        // {`${ isSaving ? 'Saving' : 'Save'}`} 
                                        disabled={ isSaving === true || isAddingCV === true } />
                                    
                                    {/* { 
                                        <input name='saveChanges_2'
                                            type='submit' 
                                            className='profile-btn-itself'
                                            style={{ visibility: !isSaving ? 'visible' : 'hidden' }}
                                            value='Save' />
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="div-header">
                        Company Profile <span style={{color: 'red'}}> </span>
                    </div> */}
                    </form>
                }
            </div>);
}

export default CompanyProfile;

