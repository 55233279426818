
import './Toast.css';


import { useToastDispatchContext } from './ToastContext';//    context/ToastContext';

export default function Toast({ type, message, id }) {
  const dispatch = useToastDispatchContext();
  return (
    <>
      {
        type == 'success' && (
            <div className='toast success-bg'>

              <div className="vertical-bar success"></div>
              <div className="semi-circle-container">
                <div className="semi-circle success">&#10004;</div>
              </div>
              <div className="message">{ message }</div>
              <div className="empty-space">
              </div>
              <div className='del-btn success-btn' onClick={() => { dispatch({ type: 'DELETE_TOAST', id: id }); }}>
                  <i className="fa fa-times" aria-hidden="true"></i>
              </div>
                
            </div>
        )
      }

      {
        type == 'error' && (
            <div className='toast error-bg'>

              <div className="vertical-bar error"></div>
              <div className="semi-circle-container">
                <div className="semi-circle error">&#33;</div>
              </div>
              <div className="message">{ message }</div>
              <div className="empty-space">
              </div>
              <div className='del-btn error-btn' onClick={() => { dispatch({ type: 'DELETE_TOAST', id: id }); }}>
                  <i className="fa fa-times" aria-hidden="true"></i>
              </div>
                
            </div>
        )
      }

      {
        type == 'update' && (
            <div className='toast info-bg'>

              <div className="vertical-bar info"></div>
              <div className="semi-circle-container">
                <div className="semi-circle info">i</div>
              </div>
              <div className="message">{ message }</div>
              <div className="empty-space">
              </div>
              <div className='del-btn info-btn' onClick={() => { dispatch({ type: 'DELETE_TOAST', id: id }); }}>
                  <i className="fa fa-times" aria-hidden="true"></i>
              </div>
                
            </div>
        )
      }

      {
        type == 'warning' && (
            <div className='toast warning-bg'>

              <div className="vertical-bar warning"></div>
              <div className="semi-circle-container">
                <div className="semi-circle warning">&#33;</div>
              </div>
              <div className="message">{ message }</div>
              <div className="empty-space">
              </div>
              <div className='del-btn warning-btn' onClick={() => { dispatch({ type: 'DELETE_TOAST', id: id }); }}>
                  <i className="fa fa-times" aria-hidden="true"></i>
              </div>
                
            </div>
        )
      }
      
      {/* 
      {type == 'update' && (
        <div className="rounded-md bg-indigo-50 p-4 m-3">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-indigo-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-indigo-800">{message}</p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  onClick={() => {
                    dispatch({ type: 'DELETE_TOAST', id: id });
                  }}
                  className="inline-flex bg-indigo-50 rounded-md p-1.5 text-indigo-500 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-50 focus:ring-indigo-600">
                  <span className="sr-only">Dismiss</span>

                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {type == 'warning' && (
        <div className="rounded-md bg-yellow-50 p-4 m-3">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-yellow-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-yellow-800">{message}</p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  onClick={() => {
                    dispatch({ type: 'DELETE_TOAST', id: id });
                  }}
                  className="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600">
                  <span className="sr-only">Dismiss</span>

                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}

    </>
  );
}





































/*
import './Toast.css';

import { useEffect, useState, useRef, useMemo } from "react";


const Toast = ({ newToast }) => {
    
    console.log("inside toast");

    const [toasts, setToasts] = useState([]);


    useEffect(() => {
        if(newToast) {
            setToasts([...toasts, newToast]);
            
            setTimeout(function() {
                close(newToast.id);
            }, 4000);
        }
    }, [newToast]);

    
    const close = (_id) => {
        console.log("we will delete a toast", _id);
        setToasts([...toasts.filter((t) => t.id !== _id)]);
    }

    return(
        <div className='toast-container'>

            {
                toasts && 
                Array.isArray(toasts) &&
                Array.length > 0 &&
                toasts.map((t) => {
                    return(
                        <div key={ t.id } className='toast'>
                            <button onClick={ () => close(t.id) }>
                            X
                            </button>
                            { t.message }
                        </div>
                    )
                })
            }
        </div> 
)}

export default Toast;

















import './Toast.css';

import { useEffect, useState, useRef, useMemo } from "react";
import { ToastContext } from './ToastContext';

export function Toast ({ message, close }) {
    
    console.log("inside toast");

    return(<div className='toast'>
        
                <button onClick={ close }>
                    message
                </button>
      
        </div> 
          
 
)}


export function ToastProvider({ children }) {

    const [toasts, setToasts] = useState([]);


    function openToast(_message) {
        const newToast = {
            id : Date.now(),
            message : _message  
        }

        setToasts((previous) => [...previous, newToast]);

    }


    function closeToast(_id) {
        setToasts((previous) => previous.filter((t) => t.id !== _id))
    }

    const contextValue = useMemo(() => ({
        open: openToast,
        close: closeToast
    }), []);

    console.log("inside ToastProvider");


    return (
        <>
            <ToastContext.Provider value={ contextValue }>
                { children } _ {toasts.length}
                
                <div style={{ float: 'right', width: '100px', height: '100px', background: 'red' }}>
                    {
                        toasts && toasts.map((t) => {
                            return(
                                <Toast key={ t.id} 
                                message={ t.message }
                                close={ closeToast(t.id) } />
                            )
                        })
                    }
                </div>
            </ToastContext.Provider>
        </>
    )
}

*/