import { useSearchParams } from 'react-router-dom';

import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import './NavBar.css';
import WebSiteIcon from '../WebSiteIcon/WebSiteIcon';

import Logo from '../../aImages/logo.jpg';
import { useEffect } from 'react';
import { useState, useRef } from 'react';

// import { useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom';

import { getUserFromCookie } from '../../Javascript_Functions_to_export/Utils';


const NavBar = ({ toggleSideBar, flag, _logo, GoBackward }) => {
//const NavBar = ({ toggleSideBar, flag, _logo }) => {
//const NavBar = ({ toggleSideBar }) => {

    //console.log("in navbar.js"); 

    let navigate = useNavigate();
    const loc = useLocation();

    const [companyLogo, setCompanyLogo] = useState(null);
    useEffect(() => {
        if(localStorage) {
            if(localStorage.getItem('Logo') == "null" || localStorage.getItem('Logo') == "undefined")
                setCompanyLogo(null);
            else {
                setCompanyLogo(localStorage.getItem('Logo'));
                //console.log("localStorage.getItem('Logo'):", localStorage.getItem('Logo'));
            }
        }

        //console.log("loc = ", loc);

        if(loc && 
           loc.state && 
           loc.pathname === '/login') {
            if(loc.state.LastVisitedPage === '/login') { 
                    GoBackward(true);
                    console.log("navbar send");
                    // last solution is window.href = 'login'
                    //window.location.href = process.env.REACT_APP_ORIGIN + "/login";
            }
            else
                    GoBackward(false);
        }
    });

    //console.log("logo=", companyLogo);
    //   const Navigate = (path) => {
    //     //history(path);
    //     window.location.href = "http://localhost:X000" + path;
    //   }
    // const changeVisibility = () => {
    //     toggleSideBar(!flag);
    // }


    console.log("location = ", loc);

    const logout = () => {

        console.log("path = ", loc);
        //return;
        show_hideLogoutNProfile();

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        //fetch('http://localhost:808x/logout', {
        fetch(`${process.env.REACT_APP_URL}/logout`, {
            method: 'GET',
            mode: 'no-cors',
            redirect: 'follow',
            credentials: 'include',
            headers: headers
        })
        .then((res) => {
            //toast("You have logged out successfully");
            
            // We commented this line, because we want to leave the user in the same page. 
            // and not redirect him to another page
            //navigate('/login');

            //return;


            if(localStorage)
                localStorage.removeItem('Logo');

            // So once the user logout. refresh the page

            if(!loc || !loc.pathname)
                //window.location.href = "http://localhost:300x";
                window.location.href = process.env.REACT_APP_ORIGIN;
            else {
                if(loc.pathname === '/admin')
                    //window.location.href = "http://localhost:300x";
                    window.location.href = process.env.REACT_APP_ORIGIN;
                else  // common section (to write inside a class)
                    if(loc.pathname == "/search" && localStorage.getItem('search'))
                        //window.location.href = "http://localhost:300x" + loc.pathname + localStorage.getItem('search');
                        window.location.href = process.env.REACT_APP_ORIGIN + loc.pathname + localStorage.getItem('search');
                    else {
                        if(loc.pathname === '/signup')
                            //window.location.href = "http://localhost:300x";    
                            window.location.href = process.env.REACT_APP_ORIGIN;    
                        else
                            //window.location.href = "http://localhost:300x" + loc.pathname;
                            window.location.href = process.env.REACT_APP_ORIGIN + loc.pathname;
                    }
            } 
        })
    }
    const screenRef = useRef(null);
    const profileRef = useRef(null);

    const [currentUser, setCurrentUser] = useState(null);
    
    useEffect(() => {
        
        if(document.cookie) {
            //onsole.log("currentUser has been set", getUserFromCookie(document.cookie));
            setCurrentUser(getUserFromCookie(document.cookie)); 
        }
    }, [document.cookie]);


    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);

    }, []);
    
    const show_hideLogoutNProfile = () => {
        var logoutBox = document.getElementById('logout-profile');
        if(!logoutBox)
            return;

        if(!logoutBox.classList.contains('opened')) {
            logoutBox.classList.add('opened');
            logoutBox.style.visibility = 'visible';
        }
        else {
            logoutBox.classList.remove('opened');
            logoutBox.style.visibility = 'hidden';
        }
    }

    const handleOutsideClick = (e) => {
        if (screenRef.current && !screenRef.current.contains(e.target) &&
            profileRef.current && !profileRef.current.contains(e.target) ) {

            var logoutBox = document.getElementById('logout-profile');
            if(!logoutBox)
                return;
            
            logoutBox.classList.remove('opened');
            logoutBox.style.visibility = 'hidden';
        }
    }

    const resetLocalStorage_Mobile = (_children) => {
        //debugger;
        if(_children.toLowerCase() === 'search for a job')
            localStorage.removeItem('search');
        else 
            if(_children.toLowerCase() === 'log in') {
                //console.log("refresh");
                //window.location.href = process.env.REACT_APP_ORIGIN + "/login";
            }
    }


    return (
    
        <div className='navbar'>
            <span className='img-span'>
                <img src={ Logo } 
                    alt="logo" 
                    style={{ zIndex: '80', 
                            width: '45px',
                            height: '45px',
                            position: 'relative',
                            top: '7px',
                            left: '120px',
                            //left: '75px',
                    }} />
            </span>
            
            <div className='name-div' style={{ 
                color: 'rgb(2 54 253)',
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                left: '190px',
                // top: '-3px',
                fontSize: '25px',
                fontWeight: '700',
                height: '60px'
            }}>
                Hire Me World
            </div>

            <div id='logout-profile' style={{ visibility: 'hidden' }} ref={ screenRef }>

                {
                    // Only if the account a company, show the "Profile" link
                    currentUser && (currentUser.AccountType === 1 || currentUser.AccountType === 2) ?
                    <div className="profile-row">
                        <div className="profile-logo">
                            <i className="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <div className="profile-title" onClick={ show_hideLogoutNProfile } >
                            <Link to={`/company/details/${ currentUser.ID }`}
                                  state={{ LastVisitedPage: '/company/details/' + currentUser.ID }}>
                                Profile
                            </Link>
                        </div>
                    </div> : ''
                }

                <div className="change-password-row">
                    <div className="change-password-logo">
                        <i className="fa fa-key" aria-hidden="true"></i>
                    </div>
                    <div className="change-password-title" onClick={ show_hideLogoutNProfile } >
                        <Link to="/changepassword" 
                              state={{ LastVisitedPage: '/changepassword' }}>
                            Change Password
                        </Link>
                    </div>
                </div>

                <div className="logout-row">
                    <div className="logout-logo">
                        <i className="fa fa-sign-out" aria-hidden="true"></i>
                    </div>
                    <div className="logout-title" onClick={ logout }>
                        Logout
                    </div>
                </div>
            </div>

            <ul className='laptop'>
                {/* <CustomLink to="/websiteicon">Icon</CustomLink> */}
                
                <CustomLink to="/">Home</CustomLink>
                <CustomLink to="/search">Search For a Job</CustomLink>
                <CustomLink to="/postjob">Post a Job</CustomLink>
                <CustomLink to="/contactus">Contact Us</CustomLink>
                {
                    !document.cookie ?
                    <>
                    <CustomLink to="/login">Log In / Sign Up</CustomLink>
                    <li className='no-bg-color' style={{ width: '55px', minWidth: '55px', }}>
                        <div style={{ 
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50%', 
                            background: 'white', 
                            width: '55px',
                            minWidth: '55px',
                            height: '55px',
                            marginTop: '2px', }}>
                                <i className="fa fa-user" 
                                aria-hidden="true"
                                style={{ color: '#c1c1c1', fontSize: '41px' }} >
                                </i>
                        </div>
                    </li>
                    </>
                    :  // else (=document.cookie exists)
                    <li className='no-bg-color' style={{ width: '55px', minWidth: '55px', }}>
                        <div onClick={ show_hideLogoutNProfile } 
                             style={{ cursor: 'pointer' }}
                             ref={ profileRef } >

                            {
                                // Admin
                                currentUser &&
                                currentUser.AccountType === 2 &&
                                <img src={ "/AdminLogo.jpg" } 
                                     alt='admin' 
                                     style={{ width: '55px', minWidth: '55px', height: '55px' }}/>
                            }


                            {
                                // Employer has already a logo
                                currentUser && companyLogo &&
                                currentUser.AccountType === 1 &&
                                <img src={ "/" + companyLogo } 
                                     alt='logged in profile' 
                                     style={{ width: '55px', 
                                              minWidth: '55px', 
                                              height: '55px', 
                                              marginTop: '2px',
                                              borderRadius: '50%' }}/>
                            }
                            {
                                // Employer while setting companyLogo for the first time
                                currentUser && !companyLogo &&
                                currentUser.AccountType === 1 &&
                                <img src='/Logged_In.png' 
                                     alt='logged in profile' 
                                     style={{ width: '55px', 
                                              minWidth: '55px', 
                                              height: '55px',
                                              marginTop: '2px',}}/>
                            }
                            {
                                // Candidate
                                currentUser && 
                                currentUser.AccountType === 0 && 
                                <img src='/Logged_In.png' 
                                     alt='logged in profile' 
                                     style={{ width: '55px', 
                                              minWidth: '55px', 
                                              height: '55px',
                                              marginTop: '2px', }}/>
                            }
                        </div>
                    </li>
                }

                {/* https://www.youtube.com/watch?v=SLfhMt5OUPI   @ 15:30  */}

            </ul>

            
            <ul className='mobile'>
                {/* <CustomLink to="/websiteicon">Icon</CustomLink> */}
                
                {
                    !document.cookie ?
                    <>
                    <li className='no-bg-color' style={{ width: '55px', minWidth: '55px', }}>
                        <div style={{ 
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50%', 
                            background: 'white', 
                            width: '55px',
                            minWidth: '55px',
                            height: '55px',
                            marginTop: '15px', }}>
                                <i className="fa fa-user" 
                                aria-hidden="true"
                                style={{ color: '#c1c1c1', fontSize: '41px' }} >
                                </i>
                        </div>
                    </li>

                    {/* <CustomLink to="/login">
                        <i className="fa fa-sign-in" aria-hidden="true"></i>
                    </CustomLink> */}

                    <li>
                        <Link to="/login" 
                            state={{ LastVisitedPage: "/login", tabIsClicked: true }} 
                            onClick={ () => resetLocalStorage_Mobile("login") }>
                                <i className="fa fa-sign-in" aria-hidden="true"></i>
                        </Link>
                    </li>

                    </>
                    :  // else (=document.cookie exists)
                    <li className='no-bg-color' style={{ width: '55px', minWidth: '55px', }}>
                        <div onClick={ show_hideLogoutNProfile } 
                             style={{ cursor: 'pointer' }}
                             ref={ profileRef } >

                            {
                                // Admin
                                currentUser &&
                                currentUser.AccountType === 2 &&
                                <img src={ "/AdminLogo.jpg" } 
                                     alt='admin' 
                                     style={{ width: '55px', minWidth: '55px', height: '55px' }}/>
                            }


                            {
                                // Employer has already a logo
                                currentUser && companyLogo &&
                                currentUser.AccountType === 1 &&
                                <img src={ "/" + companyLogo } 
                                     alt='logged in profile' 
                                     style={{ width: '55px', 
                                              minWidth: '55px', 
                                              height: '55px', 
                                              marginTop: '15px',
                                              borderRadius: '50%' }}/>
                            }
                            {
                                // Employer while setting companyLogo for the first time
                                currentUser && !companyLogo &&
                                currentUser.AccountType === 1 &&
                                <img src='/Logged_In.png' 
                                     alt='logged in profile' 
                                     style={{ width: '55px', 
                                              minWidth: '55px', 
                                              height: '55px',
                                              marginTop: '15px',}}/>
                            }
                            {
                                // Candidate
                                currentUser && 
                                currentUser.AccountType === 0 && 
                                <img src='/Logged_In.png' 
                                     alt='logged in profile' 
                                     style={{ width: '55px', 
                                              minWidth: '55px', 
                                              height: '55px',
                                              marginTop: '15px', }}/>
                            }
                        </div>
                    </li>
                }

                {/* <CustomLink to="/">
                    <i className="fa fa-home" aria-hidden="true"></i>
                </CustomLink> */}

                <li>
                    <Link to="/" 
                        state={{ LastVisitedPage: "/", tabIsClicked: true }} 
                        onClick={ () => resetLocalStorage_Mobile("Home") }>
                            <i className="fa fa-home" aria-hidden="true"></i>
                    </Link>
                </li>

                <li>
                    <Link to="/search" 
                        state={{ LastVisitedPage: "/search", tabIsClicked: true }} 
                        onClick={ () => resetLocalStorage_Mobile("Search For a Job") }>
                            <i className="fa fa-search" aria-hidden="true"></i>
                    </Link>
                </li>

                <li>
                    <Link to="/postjob" 
                        state={{ LastVisitedPage: "/postjob", tabIsClicked: true }} 
                        onClick={ () => resetLocalStorage_Mobile("Post a Job") }>
                            <i className="fa fa-plus-square-o" aria-hidden="true"></i>
                    </Link>
                </li>

                <li>
                    <Link to="/contactus" 
                        state={{ LastVisitedPage: "/contactus", tabIsClicked: true }} 
                        onClick={ () => resetLocalStorage_Mobile("Contact Us") }>
                            <i className="fa fa-envelope-o" aria-hidden="true"></i>
                    </Link>
                </li>

            </ul>
        </div>

    )
}

const CustomLink = ({ to, children, deleteLocalStorage }) => {
   
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname });
   
    var location = useLocation();

    //console.log("nav bar.js | LastVisitedPage: location.pathname = ", location.pathname);

    // Reset the localStorage just if the user clicks the "Search for a Job link"
    const resetLocalStorage = (_children) => {
        //debugger;
        if(_children.toLowerCase() === 'search for a job')
            localStorage.removeItem('search');
        else 
            if(_children.toLowerCase() === 'log in') {
                //console.log("refresh");
                //window.location.href = process.env.REACT_APP_ORIGIN + "/login";
            }
    }


    return(
        <li className={ isActive ? 'active' : '' } >
            <Link to={ to } 
                  state={{ LastVisitedPage: location.pathname, tabIsClicked: true }} 
                  onClick={ () => resetLocalStorage(children) }>
                { children }
            </Link>
        </li>
    );
}

export default NavBar;