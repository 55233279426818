

//import { sleep } from '../../Javascript_Functions_to_export/Utils';
import './FileUploader.css';

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import { useToast } from '../../CustomeHooks/useToast';

import z_photopea from '../../aImages/z1.png';

import { useState, useEffect, useRef } from 'react';

const FileUploader = ({ XXX, multiple, isAddingFn, _accept, btnText="Browse" }) => {
//const FileUploader = ({ XXX, multiple, isAddingFn }) => {

    console.log("accept format = ", _accept);

    const toast = useToast();

    const [files, setFile] = useState([]);
    const [isAdding, setIsAdding] = useState(false);

    const idCounter = useRef(1);
    const filesChanged = useRef(false);

    useEffect(() =>{
        if(filesChanged.current)
            XXX(files);
    }, [files]);

    //console.log("files = ", files);

    const upload = (event) => {
        //const allFiles = event.target.files;  // gives all selected files, if multiple attribute is on.

        if(!event?.target?.files) {
            console.log("cancel has been chosen");
            return;
        }

        if(event.target.files.length > 0) {
                
            if (event.target.files[0].size > 2 * 1024 * 1024) {  // the original size is in bytes
                toast("warning", 'File size should not exceed 2 Mb.');
            } 

            else { 
                //debugger;
                //check the file extension
                console.log("file = ", event.target.files[0]);
                var extensions = _accept.split(/[,/ .]/);
                
                extensions.forEach(myFunction);

                function myFunction(item, index, arr) {
                    arr[index] = item.toLowerCase();
                }

                var lastDot = event.target.files[0].name.lastIndexOf('.');
                var fileExt = event.target.files[0].name.slice(lastDot + 1);
                
                console.log("extensions = ", extensions, "fileExt = ", fileExt);

                if(!extensions.includes(fileExt.toLowerCase())) {
                    toast("warning", 'Extension not allowed.');
                    return;
                }

                if(event.target.files[0].name.length > 30) {
                    toast("warning", 'File name should be under 30 characters.');
                }
    
                else {
                    // set the flag to true, 
                    setIsAdding(true);
                    
                    isAddingFn(true);
                    
                    // async function 
                    // call the async function
                    putFilesInTheList(event)
                    .then(() => {
                        // after finishing the whole code in the async function,
                        // it enters in the ".then(()) block"
                        setIsAdding(false);
                        isAddingFn(false);
                        filesChanged.current = true;

                        //XXX(files);
                        //getFiles(files);
                        //_onChange(files);

                    })
                    .catch((e) => {
                        console.log("error = ", e);
                    })
                    .finally(() => {
                        
                    });
                }
            }
        }

        // old code was in the setTimeout, for testing.
        //setTimeout(() => {   }, 4000);
    }

    const putFilesInTheList = async (event) => {

        //console.log("event.target.files" , typeof event.target.files);

        // freeze the process for a while, then set the "files" state
        await new Promise(resolve => setTimeout(resolve, 300));
        
        //await setTimeout(() => {   }, 4000); did not work
        // sleep(); did not work

        //var oldFiles = [...files];    // if you want to allow user to upload more than one file
        var oldFiles = [];              // if you want to allow user to upload just one file (example his "CV").
        
        for (let i = 0; i < event.target.files.length; i++) {

            event.target.files[i].id = idCounter.current;
            idCounter.current = idCounter.current + 1;
            
            event.target.files[i].URL = URL.createObjectURL(event.target.files[i]);

            oldFiles.push(event.target.files[i]);
        }
        
        setFile(oldFiles);

        //setImageURL(URL.createObjectURL(event.target.files[0]));

    }


    //console.log("files = ", files);
    
    // const removeFile = (_id) => {
    //     setFile(files.filter(f => f.id !== _id));
    // }

    return(

        <div className='file-uploader-container'>

           

            <div className='upload-plus-accept'>
    
                <input id="input-file-uploader"
                    name ="input-file-uploader"
                    className='btn-upload'
                    type="file"
                    //accept="image/*"
                    //accept="image/png, image/jpeg, image/*, .pdf, .docx,.xml"
                    //accept=".zip, .pdf, .docx"
                    accept={ _accept }
                    //  from "hiremeworld" website : Additional File (2MB max): (.zip,.doc,.docx,.pdf,.txt)
                    multiple={ multiple }
                    disabled = { isAdding ? "disabled" : "" }
                    onChange={ (e) => { upload(e) } }/>

                <label className='uploader-label' 
                    htmlFor="input-file-uploader"
                    style={{ opacity:isAdding ? "0.3" : "1", cursor:isAdding ? "not-allowed" : "pointer" }}>
                    { isAdding ? "Importing" : `${ btnText }` } 
                </label>                
                <div style={{ color: '#999999', fontSize: '15px' }}>
                    { _accept === 'image/png, image/jpeg, image/jpg' ? '.png, .jpeg, .jpg' : _accept } 
                    &nbsp; up to 2 Mb.
                </div>

                {/* <progress style={{ visibility : isAdding ? "visible" : "hidden", 
                                width: "210px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "15px" }} 
                        id="adding-progress-bar" 
                        max="100" ></progress> */}
  
            </div>

            <div className='selected-files'>
                { files &&
                    files.map((f, key) => {
                        return (
                            <div className='file-container' key={key}>
                                {/* 
                                in hiremeworld case, if we delete a file accidentally, 
                                and we want to upload it again, we cannot, because the type='file'
                                is a big 'DUNKY', he stills remember the file even we delete it. 
                                
                                <i className="delete-file" onClick={() => removeFile(f.id)}>
                                    [delete]  
                                </i>
                                <br/> */}
                                {/* <img src={ f.URL } alt= {f.name} /> */}
                                <div>
                                { 
                                   f.type === 'application/pdf' ? 
                                   <i className="fa fa-file-pdf-o" aria-hidden="true"></i> :

                                   f.type === 'application/x-zip-compressed' ? 
                                    <img src={z_photopea} 
                                        alt='.zip' 
                                        style={{ width: '40px', height: '40px' }}/> :

                                   f.type === 'image/png' ? 
                                   <img src='/png_Logo.png' 
                                        alt='.png' 
                                        style={{ width: '40px', height: '40px' }}/> :

                                    f.name.slice(f.name.lastIndexOf('.') + 1).toLowerCase().trim() === 'jpg' ? 
                                    <img src='/jpg_Logo.png' 
                                            alt='.jpg' 
                                            style={{ width: '40px', height: '40px' }} /> :

                                    f.name.slice(f.name.lastIndexOf('.') + 1).toLowerCase().trim() === 'jpeg' ? 
                                    <img src='/jpeg_Logo.png' 
                                            alt='.jpeg' 
                                            style={{ width: '40px', height: '40px' }} /> :

                                   <i className="fa fa-file-word-o" aria-hidden="true"></i>
                                }
                                </div>

                                <div className='filename-filesize'>
                                    <div style={{ marginBottom: '4px', fontWeight: 'bold', fontSize: '16px' }}>
                                        { f.name }
                                    </div>
                                    <div style={{ fontSize: '12px', color: '#787474' }}>
                                        { Math.round(f.size / 1024) } Kb
                                        {/* <span style={{ color: 'green', marginLeft: '4px' }}>Completed</span> */}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>


                {/* <input type='range'
                    id="slider"
                    min="0.1"
                    max="10"
                    step="1" 
                    value="5"/> */}
    
        </div>
    );
}

export default FileUploader;