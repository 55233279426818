

import './HomePage.css';
import WebSiteIcon from '../MyComponents/WebSiteIcon/WebSiteIcon';
import handsomePerson from '../aImages/happy_person_5_trans_1.png';
import hiringWhite from '../aImages/hiring_white.jpg';
import { Link } from 'react-router-dom';
import CountUp from '../MyComponents/CountUp/CountUp';
import Footer from '../MyComponents/Footer/Footer';
import { getJobCategories } from '../Javascript_Functions_to_export/Utils';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import { useLocation } from 'react-router-dom';

//import tappayments from '@api/tappayments';


const HomePage = () => {

    const [applicantsNumber, setApplicantsNumber] = useState(0);
    const [candidatesnb, setCandidatesnb] = useState(0);
    const [companiesNumber, setCompaniesNumber] = useState(0);
    const [jobsNumber, setJobsNumber] = useState(0);

    const loc = useLocation();

    //console.log("loc=", loc);

    axios.defaults.withCredentials = true;
    
    useEffect(() => {

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        fetch('https://hiremeworld.com', {
            method: 'GET',
            //mode: 'no-cors', //'same-origin',
            //redirect: 'follow',
            credentials: 'include', // Don't forget to specify this if you need cookies
            headers: headers,
        })
        .then((res) => {
            console.log("res 1", res);
        });


        
        axios.get('https://hiremeworld.com', { headers: headers })   // for test worked and showed the alert
        .then(res => {
            console.log("res 2", res);
        })
        .catch(res => {
            console.log("res.data.message", res.data.message);
        })

        // axios.get('https://hiremeworld.com:8443/applicantsnumber') // did not work
        // .then(res => {
        //     console.log("res 3", res.status);  
        // })

        // axios.get('https://hiremeworld.com:8443/applicantsnumber/') // did not work
        // .then(res => {
        //     console.log("res 4", res.status); 
        // })

        // axios.get('https://hiremeworld.com/')   // did not work
        // .then(res => {
        //     alert("nothing");
        // })

        // axios.get('https://hiremeworld.com/applicantsnumber/') // did not work
        // .then(res => {
        //     console.log(res);
        // })

        // axios.get('https://hiremeworld.com:3306/applicantsnumber/') // did not work
        // .then(res => {
        //     console.log(res);
        // })
        

        //axios.get('http://localhost:808x/applicantsnumber')
        axios.get(`${process.env.REACT_APP_URL}/applicantsnumber`)
        .then(res => {
            setCandidatesnb(res.data[0].CandidatesNumber);
        })

        //axios.get('http://localhost:808x/candidatesnb')
        axios.get(`${process.env.REACT_APP_URL}/candidatesnb`)
        .then(res => {
            setCandidatesnb(res.data[0].CandidatesNumber);
        })

        //axios.get('http://localhost:808x/companiesnumber')
        axios.get(`${process.env.REACT_APP_URL}/companiesnumber`)
        .then(res => {
            setCompaniesNumber(res.data[0].NumberOfCompanies);
        })

        //axios.get('http://localhost:808x/postedjob_number')
        axios.get(`${process.env.REACT_APP_URL}/postedjob_number`)
        .then(res => {
            setJobsNumber(res.data[0].NumberOfPostedJobs);
        });
        
        // var headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        // headers.append('Accept', 'application/json');

        // fetch('http://localhost:808x/postedjob_number', {
        //     method: 'GET', // POST
        //     mode: 'no-cors', //'same-origin',
        //     redirect: 'follow',
        //     credentials: 'include', // Don't forget to specify this if you need cookies
        //     headers: headers,
        //     //body: JSON.stringify({
        //     //    first_name: 'John',
        //     //    last_name: 'Doe'
        //     //})
        // })

        // return;

    },[]);


    return(   <div className="big-home-container every-page-container">
        
        <div className="home-container">

            <div className='light-color'>

            </div>
            
            <div className='dark-color'>

            </div>

            <div className='texts'>
                <div className='text1'>
                    <div className='right-sign'>&#10004;</div> 
                    <div className='stay-connect'>
                        Stay connected to get upcoming job with
                        <font color='#1965CF'><i><b> HireMeWorld</b></i></font>
                    </div>
                    {/* <div className='website-word'> HireMe</div> */}
                </div>
                <div className='text2'>
                    Find Your<br/>Perfect <span className='job-word'>Job</span><br/> Platform
                </div>
                <div className='text3'>
                    Explore all the most exciting job roles based on your interest and <br/>
                    study major. your dream job is waiting for you.
                </div>
                <div className='find-job-button'>
                    <Link to='/search'>
                        Find Jobs
                    </Link> 
                </div>
            </div>

            <div style={{ display: 'flex', zIndex: '3',
                          flexDirection: 'row',
                          alignSelf: 'center',
                          justifyContent: 'center', position: 'absolute', right: '0', top: '410px' }}>
                <div className='big-circle'>

                </div>
                <div className='white-circle'>

                </div>
                <i className="fa fa-file-pdf-o pdf-icon" aria-hidden="true"></i>

                <div className='plus-sign'>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                </div>
          
                <i className="fa fa-search search-sign" aria-hidden="true"></i>

                <div className='upload-div'>
                    <span><i className="fa fa-paperclip paperclip" style={{ fontSize: '34px'}} aria-hidden="true"></i></span>
                    <span className='upload-cv'>Upload CV</span>
                </div>

                <img src={ handsomePerson } 
                     className='handsome-img'
                     alt='handsome' />
            </div>

        </div>


        <div className='jobs-statistics-section'>

            <img src={ hiringWhite } alt="Hiring" className='bottom-img' />

            <div className='bottom-bg'>
                            <div style={{
                                fontWeight: '600',
                            }}>Our Community
                            </div>
                            <div className='join-community'>
                                Join our community of talented<br/>
                                and professionals by applying<br/>for a job today!
                        </div>
            </div>

            <div className='div-data'>
                <div className='counting'>
                    <div className='rotated-div'></div>
                    <div className='logo'><i className="fa fa-file-text-o" aria-hidden="true"></i></div>
                    <div className='number companies'>
                        { applicantsNumber >= 2 ? applicantsNumber - 1 + "+" : applicantsNumber }
                    </div>
                    <div className='title'>User{ applicantsNumber >= 2 ? 's' : '' } Application{ applicantsNumber >= 2 ? 's' : '' }</div>
                </div>
                <div className="counting">
                    <div className='rotated-div'></div>
                    <div className='logo'><i className="fa fa-building-o" aria-hidden="true"></i></div>
                    <div className='user-applications number'>
                        { companiesNumber >= 2 ? companiesNumber - 1 + "+" : companiesNumber }
                    </div>
                    <div className='title'>Compan{ companiesNumber >= 2 ? 'ies' : 'y' }</div>
                </div>
                <div className='counting'>
                    <div className='rotated-div'></div>
                    <div className='logo'><i className="fa fa-users" aria-hidden="true"></i></div>
                    <div className='posted-jobs number'>{ candidatesnb >= 2 ? candidatesnb - 1 + "+" : candidatesnb }</div>
                    <div className='title'>User{ candidatesnb >= 2 ? 's' : '' }</div>
                </div>
                <div className='counting'>
                    <div className='rotated-div'></div>
                    <div className='logo'><i className="fa fa-th-large" aria-hidden="true"></i></div>
                    <div className='categories number'>
                        { jobsNumber >= 2 ? jobsNumber - 1 + "+" : jobsNumber }
                    </div>
                    <div className='title'>Job{ jobsNumber >= 2 ? 's' : '' }</div>
                </div>
            </div>
        </div>

        <div className='jobs-statistics-section2'>
                
        </div>
        <Footer />

        </div>
    );

}

export default HomePage;

