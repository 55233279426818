


import { useEffect, useState } from "react";
import axios from "axios";
import './Admin.css';

import { getUserFromCookie } from '../../Javascript_Functions_to_export/Utils';
import { useNavigate } from "react-router-dom";

import { useToast } from "../../CustomeHooks/useToast";

import { showLocalDateAndTime } from '../../Javascript_Functions_to_export/Utils';

const Admin = () => {

    const navigate = useNavigate();
    const toast = useToast();

    const [companyName, setCompanyName] = useState('');
    const [jobs, setJobs] = useState();
    useEffect(() => {
        console.log("I am the admin");
        // fetch all inactive jobs to active the one I want. 

        // user navigate to this page manually (using url)
        if(!document.cookie) {
            //toast('warning', 'Please login first !');
            navigate('/login');
        }
        else {
            if(getUserFromCookie(document.cookie).AccountType === 2) {   // Admin=2
                //axios.get('http://localhost:808x/admin')
                axios.get(`${process.env.REACT_APP_URL}/admin`)
                .then((res) => {
                    setJobs(res.data);
                })
                .catch((err) => {

                });
            }
            else {
                navigate('/login');
            }
        }
    }, []);

    axios.defaults.withCredentials = true;
    const activateJob = (_job) => {
        //console.log("id = ", _job.JobID);
        axios.put(`${process.env.REACT_APP_URL}/activate_job`, { jobID: _job.JobID })
        .then(res => {
            //if(res.data.status === 'success') {
                alert(res.data.message);
                //navigate('/admin');
                //window.location.href = "http://localhost:300x/admin";
                window.location.href = process.env.REACT_APP_ORIGIN + "/admin";
            //}
            //else
                //toast("error", res.data.error);
        })
        .catch(err => {
            if(err.response.status === 401) {
                toast("error", err.response.data.message);
            }
        })
        .finally(() => {
            //setIsSaving(false);
        })
    }

    const deleteCVs = () => {
        // delete 1 month ago CVs programmatically from the /public/XXX path.
    }

    const changeStatus = (e, job) => {
        if(e && e.target)
            setJobs(jobs.map(j => j.JobID === job.JobID ?
                { ...j, isSel: e.target.checked } :
                j ));            
    }

    const activateSelJobs = () => {

        // Check if all selected jobs came from the same company.[]
        if(!jobs || jobs.length === 0)
            return;

        var selectedJobs = jobs.filter(j => j.isSel === true);
        
        if(!selectedJobs || selectedJobs.length === 0) {
            toast("error", "selectedJobs.length === 0");
            return;
        }

        var companyID = selectedJobs[0].ID;
        console.log("companyID=", companyID);

        var jobsFromOtherCompany = selectedJobs.filter(j => j.ID !== companyID);
        console.log("jobsFromOtherCompany=", jobsFromOtherCompany);

        if(jobsFromOtherCompany && jobsFromOtherCompany.length > 0) {
            toast("error", "not all jobs are from the same company");
            return;
        }

        var jobsIDs = [];
        var jobsTitles = "";
        
        for (let i = 0; i < selectedJobs.length; i++) {
            jobsIDs.push("'" + selectedJobs[i].JobID + "'");
            jobsTitles += "<li>" + selectedJobs[i].JobTitle + "</li>@@@";
        }

        jobsIDs = jobsIDs.join(",");

        //var jobsTitles = "<li>Test 1</li>@@@<li>Test 2</li>@@@";
        //var jobsIDs = '240';
        
        console.log("jobsIDs=", jobsIDs, "jobsTitles=", jobsTitles);
        
        // go to server
        axios.put(`${process.env.REACT_APP_URL}/activate_job`, { jobsIDs: jobsIDs, jobsTitles : jobsTitles })
        .then(res => {
            //if(res.data.status === 'success') {
                //alert(res.data.message);
                //navigate('/admin');
                //window.location.href = "http://localhost:300x/admin";
                window.location.href = process.env.REACT_APP_ORIGIN + "/admin";
            //}
            //else
                //toast("error", res.data.error);
        })
        .catch(err => {
            if(err.response.status === 401) {
                toast("error", err.response.data.message);
            }
        })
        .finally(() => {
            //setIsSaving(false);
        })
    }
    
    return(
    <>
        <div className="admin-container every-page-container">
            
            {
                document.cookie && 
                getUserFromCookie(document.cookie).AccountType === 2 &&
                <form>
                    <div>
                        admin page
                    </div> <br/>
                    
                    Search for a Company Name : 

                    <input name="compamy-name"
                        type="text"
                        value={ companyName }
                        onChange={ (e) => setCompanyName(e.target.value) }
                        placeholder="Company Name"/><br/><br/><br/>

                    <button type="button" onClick={ activateSelJobs }>Activate selected jobs</button><br/><br/>

                    <div className="headers">
                        <span className='header' style={{ width: '80px', }}>Selection</span>
                        <span className='header' style={{ width: '80px', }}>Job ID</span>
                        <span className='header' style={{ width: '100px', }}>Username</span>
                        <span className='header' style={{ width: '210px', }}>Company Name</span>
                        <span className='header' style={{ width: '250px', }}>Email</span>
                        <span className='header' style={{ width: '300px', }}>Job Title</span>
                        <span className='header' style={{ width: '150px', }}>Posted date</span> 
                        <span className='header' style={{ width: '110px', }}>Is Active</span>
                        {/* <span className='header' style={{ width: '100px', }}>Action</span> */}
                    </div>
                    
                    <div>
                        {
                            jobs &&
                            Array.isArray(jobs) &&
                            jobs.filter(job => job.CompanyName.toLowerCase().indexOf(companyName.toLowerCase()) >= 0).map((j, index) => {
                                return(
                                    <div key={ index } className='single-row'>

                                        <span className='value' style={{ width: '100px', }}>
                                            <input type="checkbox"
                                                    style={{ width: '20px', 
                                                            height: '20px', 
                                                            cursor: 'pointer'}}
                                                    
                                                    checked= { j.checked }
                                                    value={ j.checked } 
                                                    onChange={ (e) => changeStatus(e, j) } />
                                        </span>

                                        <span className='value'>
                                            { j.JobID }
                                        </span>
                                        <span className='value' style={{ width: '200px', }}>
                                            { j.CompanyUsername }
                                        </span>
                                        <span className='value' style={{ width: '145px', }}>
                                            { j.CompanyName }
                                        </span>
                                        <span className='value' style={{ width: '250px', }}>
                                            { j.Email }
                                        </span>
                                        <span className='value' style={{ width: '300px', }}>
                                            { j.JobTitle }
                                        </span>
                                        <span className='value' style={{ width: '170px', }}>
                                            {/* { j.PostingDate } */}
                                            
                                            {
                                                showLocalDateAndTime(j).PostingDate
                                            }

                                        </span>
                                        <span className='value' style={{ width: '130px', }}>
                                            { j.Active === 0 ? 'Inactive' : 'Active' }
                                        </span>
                                        
                                    </div>
                                )
                            })
                        }
                    </div>
                </form>
            }
        </div>
    </>)
}

export default Admin;






                                        // {
                                        //     !j.Active ?
                                        //     <span className='value' style={{ width: '100px',}}>
                                        //         <button name="activate" 
                                        //                 type="button" 
                                        //                 onClick={ () => activateJob(j) }>
                                        //             Activate
                                        //         </button>
                                        //     </span> :
                                        //     ''
                                        // }










