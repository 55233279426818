
import { useEffect, useMemo, useState } from "react";

import { sleep } from "../Javascript_Functions_to_export/Utils";

const useFetch = (url) => {

    //console.log("Inside useEffect hook");

    
    //debugger;

    //The code inside your custom Hooks will re-run during every re-render of your component,
    // like the 3 lines below.
    const [isLoading, setIsLoading] = useState(true);
    const [lsData, setLsData] = useState([]);
    const [itemsNb, setItemsNb] = useState(-1);
    

    //console.log("useFetch hook was rendered");
    
    // but the debugger will not touch the useEffect hook, because the url did not change
    
    
    const getData = async () => {  // it will not be called, unless we write "getData()";
        

        // the final conclusion : 
        // If the url did not change, the hook itself will render,
        // but the "useEffect" hook will not render, because the URL string still the same, ("aka   }, [url]   ") 
        
        setIsLoading(true);
        console.log("url = ", url);

        //console.log("getting new data in 'useFetch hook' = ");

        //'https://restcountries.com/v3.1/all'
        const response = await fetch(url);
        //sleep(4000);
        if(response.ok) { // "ok" : should be small letters, otherwise it will occured an exception
            //await waitToFetch();
            //console.log("Fetched");
            return response.json();
        }
        else {
            throw new Error("Data could not be fetched");
        }
        
        /*
        //'https://randomuser.me/api/?results=2'
        const { response } = await fetch(url);

        if(response) {
            return response;
        }
        else {
            throw new Error("Data could not be fetched");
        }*/
    }
    
    
    useEffect(() => {


        // useEffect : Runs on every render, if there is not an empty square brakets
        //try{
            
            //console.log("isLoading = true");
        
            if(!url)
                return;
            
            getData()
            .then((res) => {
                
                setLsData(res);
                
                if(res)
                    setItemsNb(res.length);

                // setLsData(
                //     res.slice(0, 10).reduce((obj, state) => ({...obj, [state.thumbnailUrl] : false }), {})
                // );
                
                //console.log("new photos", lsData);

                //console.log("data fetched");
            })
            .catch((error) => {console.log("error = ", error);})
            .finally(() => {
                setIsLoading(false);
                //console.log("finally block | setting isLoading = false");

            });

            //console.log("data not fetched yet");

        // }
        //  catch (error) {
        //      console.log("error = ", error);
        //     }
        // finally{
        //      setIsLoading(false);
        //      console.log("finally block | setting isLoading = false");
        //  }

        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [url]);

    return { lsData, isLoading, itemsNb };
    //return lsData;
}

export default useFetch;









